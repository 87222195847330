import React from 'react';
import Layout from './index';

import messages from '../data/messages/en';
import "@formatjs/intl-pluralrules/locale-data/en"

export default (props) => {
  return (<Layout
    {...props}
    messages={messages}
  />);
}
