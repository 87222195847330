import React from 'react';
import Layout from "../layouts/en.js";
import { FaPhone } from 'react-icons/fa';
import { SliderComponentEN } from '../components/SliderComponent';

const IndexPage = (props) => {
  return (
    <Layout location={props.location}>
    <div>
      <a className="fixed-phone" href="tel:+48663742379" target="_blank" rel="noreferrer"><FaPhone /></a>
      <section className="slider" style={{ height: '100vh' }} id="section1">
        <SliderComponentEN />
      </section>
    </div>
  </Layout>)
}



export default IndexPage
